import { default as affiliates34wRP55WNYMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45views6FJ3WT1AeJMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngosBgi1g05hdjMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93zb9M6lbAMcMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as index4pdslVwxf2Meta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93SpRqpSinRXMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexIocoZqNHJIMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93sRxvVh45FCMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93g8LAAXhstpMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as editOzIeOngbEDMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexj6AsVGUhVcMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analytics8AU3JYIxKHMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexZhPxbpFUdiMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexV7iDNMSN4FMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareqicZxEbsi3Meta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showCjZlhSDV0gMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexxkGjbcqmuOMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsBqfQvnyDdPMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guides4EKwlPgPkcMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homelCQMPXU2wUMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexb0m5FyZ7sxMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsM5T4eYdxQdMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as login1xFuFJORJaMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesWjyZZIuwqaMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencersIm6DmH7x3QMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93Dadj9XGJkBMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callback7edcBBYS0hMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callback8gziGrJbO8Meta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackypGfqxfUmjMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnerspOIMXEP7K3Meta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93055qrTZpPfMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as index5iZfIT9yHzMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingmZr21bg9wvMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyCZmWsbaxEoMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as register7H3vLd4CmBMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokensZ2ZyYg2Hi2Meta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountNfvvRCCF3zMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as admincQglZ680mWMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billing6BqkvOfbFvMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsZ9vBKGquvAMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainhtQdOB1N6lMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as indexgtBcgoR29KMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordRZiNMTS75SMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profilesgQnJU6ygZMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacesh1WWI8fNhFMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsPNlv2AoYAQMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorvXogfDNGlhMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successrSnbwewiFqMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsvXrgaxNFDNMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as editw48xO2GSCpMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indexV99RVN8XBfMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as indexLyx89vlrx8Meta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as shareCrghxHouHLMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showkSjucLiS06Meta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexREXdbw6jOGMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indextlKM6mGQUGMeta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsXZ3zNJtEn6Meta } from "/codebuild/output/src2404366596/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliates34wRP55WNYMeta?.name ?? "affiliates",
    path: affiliates34wRP55WNYMeta?.path ?? "/affiliates",
    meta: affiliates34wRP55WNYMeta || {},
    alias: affiliates34wRP55WNYMeta?.alias || [],
    redirect: affiliates34wRP55WNYMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45views6FJ3WT1AeJMeta?.name ?? "database-views",
    path: database_45views6FJ3WT1AeJMeta?.path ?? "/database-views",
    meta: database_45views6FJ3WT1AeJMeta || {},
    alias: database_45views6FJ3WT1AeJMeta?.alias || [],
    redirect: database_45views6FJ3WT1AeJMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngosBgi1g05hdjMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngosBgi1g05hdjMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngosBgi1g05hdjMeta || {},
    alias: discount_45students_45academics_45ngosBgi1g05hdjMeta?.alias || [],
    redirect: discount_45students_45academics_45ngosBgi1g05hdjMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zb9M6lbAMcMeta?.name ?? "features-slug",
    path: _91slug_93zb9M6lbAMcMeta?.path ?? "/features/:slug()",
    meta: _91slug_93zb9M6lbAMcMeta || {},
    alias: _91slug_93zb9M6lbAMcMeta?.alias || [],
    redirect: _91slug_93zb9M6lbAMcMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: index4pdslVwxf2Meta?.name ?? "features",
    path: index4pdslVwxf2Meta?.path ?? "/features",
    meta: index4pdslVwxf2Meta || {},
    alias: index4pdslVwxf2Meta?.alias || [],
    redirect: index4pdslVwxf2Meta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SpRqpSinRXMeta?.name ?? "form-templates-slug",
    path: _91slug_93SpRqpSinRXMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93SpRqpSinRXMeta || {},
    alias: _91slug_93SpRqpSinRXMeta?.alias || [],
    redirect: _91slug_93SpRqpSinRXMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexIocoZqNHJIMeta?.name ?? "form-templates",
    path: indexIocoZqNHJIMeta?.path ?? "/form-templates",
    meta: indexIocoZqNHJIMeta || {},
    alias: indexIocoZqNHJIMeta?.alias || [],
    redirect: indexIocoZqNHJIMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sRxvVh45FCMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93sRxvVh45FCMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93sRxvVh45FCMeta || {},
    alias: _91slug_93sRxvVh45FCMeta?.alias || [],
    redirect: _91slug_93sRxvVh45FCMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93g8LAAXhstpMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93g8LAAXhstpMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93g8LAAXhstpMeta || {},
    alias: _91slug_93g8LAAXhstpMeta?.alias || [],
    redirect: _91slug_93g8LAAXhstpMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: editOzIeOngbEDMeta?.name ?? "forms-slug-edit",
    path: editOzIeOngbEDMeta?.path ?? "/forms/:slug()/edit",
    meta: editOzIeOngbEDMeta || {},
    alias: editOzIeOngbEDMeta?.alias || [],
    redirect: editOzIeOngbEDMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexj6AsVGUhVcMeta?.name ?? "forms-slug",
    path: indexj6AsVGUhVcMeta?.path ?? "/forms/:slug()",
    meta: indexj6AsVGUhVcMeta || {},
    alias: indexj6AsVGUhVcMeta?.alias || [],
    redirect: indexj6AsVGUhVcMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showCjZlhSDV0gMeta?.name ?? undefined,
    path: showCjZlhSDV0gMeta?.path ?? "/forms/:slug()/show",
    meta: showCjZlhSDV0gMeta || {},
    alias: showCjZlhSDV0gMeta?.alias || [],
    redirect: showCjZlhSDV0gMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analytics8AU3JYIxKHMeta?.name ?? "forms-slug-show-analytics",
    path: analytics8AU3JYIxKHMeta?.path ?? "analytics",
    meta: analytics8AU3JYIxKHMeta || {},
    alias: analytics8AU3JYIxKHMeta?.alias || [],
    redirect: analytics8AU3JYIxKHMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexZhPxbpFUdiMeta?.name ?? "forms-slug-show",
    path: indexZhPxbpFUdiMeta?.path ?? "",
    meta: indexZhPxbpFUdiMeta || {},
    alias: indexZhPxbpFUdiMeta?.alias || [],
    redirect: indexZhPxbpFUdiMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexV7iDNMSN4FMeta?.name ?? "forms-slug-show-integrations",
    path: indexV7iDNMSN4FMeta?.path ?? "integrations",
    meta: indexV7iDNMSN4FMeta || {},
    alias: indexV7iDNMSN4FMeta?.alias || [],
    redirect: indexV7iDNMSN4FMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareqicZxEbsi3Meta?.name ?? "forms-slug-show-share",
    path: shareqicZxEbsi3Meta?.path ?? "share",
    meta: shareqicZxEbsi3Meta || {},
    alias: shareqicZxEbsi3Meta?.alias || [],
    redirect: shareqicZxEbsi3Meta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexxkGjbcqmuOMeta?.name ?? "forms-create",
    path: indexxkGjbcqmuOMeta?.path ?? "/forms/create",
    meta: indexxkGjbcqmuOMeta || {},
    alias: indexxkGjbcqmuOMeta?.alias || [],
    redirect: indexxkGjbcqmuOMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsBqfQvnyDdPMeta?.name ?? "forms-uploads",
    path: uploadsBqfQvnyDdPMeta?.path ?? "/forms/uploads",
    meta: uploadsBqfQvnyDdPMeta || {},
    alias: uploadsBqfQvnyDdPMeta?.alias || [],
    redirect: uploadsBqfQvnyDdPMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guides4EKwlPgPkcMeta?.name ?? "guides",
    path: guides4EKwlPgPkcMeta?.path ?? "/guides",
    meta: guides4EKwlPgPkcMeta || {},
    alias: guides4EKwlPgPkcMeta?.alias || [],
    redirect: guides4EKwlPgPkcMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homelCQMPXU2wUMeta?.name ?? "home",
    path: homelCQMPXU2wUMeta?.path ?? "/home",
    meta: homelCQMPXU2wUMeta || {},
    alias: homelCQMPXU2wUMeta?.alias || [],
    redirect: homelCQMPXU2wUMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexb0m5FyZ7sxMeta?.name ?? "index",
    path: indexb0m5FyZ7sxMeta?.path ?? "/",
    meta: indexb0m5FyZ7sxMeta || {},
    alias: indexb0m5FyZ7sxMeta?.alias || [],
    redirect: indexb0m5FyZ7sxMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsM5T4eYdxQdMeta?.name ?? "integrations",
    path: integrationsM5T4eYdxQdMeta?.path ?? "/integrations",
    meta: integrationsM5T4eYdxQdMeta || {},
    alias: integrationsM5T4eYdxQdMeta?.alias || [],
    redirect: integrationsM5T4eYdxQdMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: login1xFuFJORJaMeta?.name ?? "login",
    path: login1xFuFJORJaMeta?.path ?? "/login",
    meta: login1xFuFJORJaMeta || {},
    alias: login1xFuFJORJaMeta?.alias || [],
    redirect: login1xFuFJORJaMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesWjyZZIuwqaMeta?.name ?? "my-templates",
    path: my_45templatesWjyZZIuwqaMeta?.path ?? "/my-templates",
    meta: my_45templatesWjyZZIuwqaMeta || {},
    alias: my_45templatesWjyZZIuwqaMeta?.alias || [],
    redirect: my_45templatesWjyZZIuwqaMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencersIm6DmH7x3QMeta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencersIm6DmH7x3QMeta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencersIm6DmH7x3QMeta || {},
    alias: notion_45ambassadors_45influencersIm6DmH7x3QMeta?.alias || [],
    redirect: notion_45ambassadors_45influencersIm6DmH7x3QMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Dadj9XGJkBMeta?.name ?? "notion-form-slug",
    path: _91slug_93Dadj9XGJkBMeta?.path ?? "/notion-form/:slug()",
    meta: _91slug_93Dadj9XGJkBMeta || {},
    alias: _91slug_93Dadj9XGJkBMeta?.alias || [],
    redirect: _91slug_93Dadj9XGJkBMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callback7edcBBYS0hMeta?.name ?? "notion-callback",
    path: callback7edcBBYS0hMeta?.path ?? "/notion/callback",
    meta: callback7edcBBYS0hMeta || {},
    alias: callback7edcBBYS0hMeta?.alias || [],
    redirect: callback7edcBBYS0hMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callback8gziGrJbO8Meta?.name ?? "notion-guest-callback",
    path: guest_45callback8gziGrJbO8Meta?.path ?? "/notion/guest-callback",
    meta: guest_45callback8gziGrJbO8Meta || {},
    alias: guest_45callback8gziGrJbO8Meta?.alias || [],
    redirect: guest_45callback8gziGrJbO8Meta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbackypGfqxfUmjMeta?.name ?? "oauth-callback",
    path: callbackypGfqxfUmjMeta?.path ?? "/oauth/callback",
    meta: callbackypGfqxfUmjMeta || {},
    alias: callbackypGfqxfUmjMeta?.alias || [],
    redirect: callbackypGfqxfUmjMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnerspOIMXEP7K3Meta?.name ?? "partners",
    path: partnerspOIMXEP7K3Meta?.path ?? "/partners",
    meta: partnerspOIMXEP7K3Meta || {},
    alias: partnerspOIMXEP7K3Meta?.alias || [],
    redirect: partnerspOIMXEP7K3Meta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93055qrTZpPfMeta?.name ?? "password-reset-token",
    path: _91token_93055qrTZpPfMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93055qrTZpPfMeta || {},
    alias: _91token_93055qrTZpPfMeta?.alias || [],
    redirect: _91token_93055qrTZpPfMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: index5iZfIT9yHzMeta?.name ?? "password-reset",
    path: index5iZfIT9yHzMeta?.path ?? "/password/reset",
    meta: index5iZfIT9yHzMeta || {},
    alias: index5iZfIT9yHzMeta?.alias || [],
    redirect: index5iZfIT9yHzMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricingmZr21bg9wvMeta?.name ?? "pricing",
    path: pricingmZr21bg9wvMeta?.path ?? "/pricing",
    meta: pricingmZr21bg9wvMeta || {},
    alias: pricingmZr21bg9wvMeta?.alias || [],
    redirect: pricingmZr21bg9wvMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyCZmWsbaxEoMeta?.name ?? "privacy-policy",
    path: privacy_45policyCZmWsbaxEoMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyCZmWsbaxEoMeta || {},
    alias: privacy_45policyCZmWsbaxEoMeta?.alias || [],
    redirect: privacy_45policyCZmWsbaxEoMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: register7H3vLd4CmBMeta?.name ?? "register",
    path: register7H3vLd4CmBMeta?.path ?? "/register",
    meta: register7H3vLd4CmBMeta || {},
    alias: register7H3vLd4CmBMeta?.alias || [],
    redirect: register7H3vLd4CmBMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsPNlv2AoYAQMeta?.name ?? undefined,
    path: settingsPNlv2AoYAQMeta?.path ?? "/settings",
    meta: settingsPNlv2AoYAQMeta || {},
    alias: settingsPNlv2AoYAQMeta?.alias || [],
    redirect: settingsPNlv2AoYAQMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokensZ2ZyYg2Hi2Meta?.name ?? "settings-access-tokens",
    path: access_45tokensZ2ZyYg2Hi2Meta?.path ?? "access-tokens",
    meta: access_45tokensZ2ZyYg2Hi2Meta || {},
    alias: access_45tokensZ2ZyYg2Hi2Meta?.alias || [],
    redirect: access_45tokensZ2ZyYg2Hi2Meta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountNfvvRCCF3zMeta?.name ?? "settings-account",
    path: accountNfvvRCCF3zMeta?.path ?? "account",
    meta: accountNfvvRCCF3zMeta || {},
    alias: accountNfvvRCCF3zMeta?.alias || [],
    redirect: accountNfvvRCCF3zMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: admincQglZ680mWMeta?.name ?? "settings-admin",
    path: admincQglZ680mWMeta?.path ?? "admin",
    meta: admincQglZ680mWMeta || {},
    alias: admincQglZ680mWMeta?.alias || [],
    redirect: admincQglZ680mWMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billing6BqkvOfbFvMeta?.name ?? "settings-billing",
    path: billing6BqkvOfbFvMeta?.path ?? "billing",
    meta: billing6BqkvOfbFvMeta || {},
    alias: billing6BqkvOfbFvMeta?.alias || [],
    redirect: billing6BqkvOfbFvMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsZ9vBKGquvAMeta?.name ?? "settings-connections",
    path: connectionsZ9vBKGquvAMeta?.path ?? "connections",
    meta: connectionsZ9vBKGquvAMeta || {},
    alias: connectionsZ9vBKGquvAMeta?.alias || [],
    redirect: connectionsZ9vBKGquvAMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainhtQdOB1N6lMeta?.name ?? "settings-custom-domain",
    path: custom_45domainhtQdOB1N6lMeta?.path ?? "custom-domain",
    meta: custom_45domainhtQdOB1N6lMeta || {},
    alias: custom_45domainhtQdOB1N6lMeta?.alias || [],
    redirect: custom_45domainhtQdOB1N6lMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: indexgtBcgoR29KMeta?.name ?? "settings",
    path: indexgtBcgoR29KMeta?.path ?? "",
    meta: indexgtBcgoR29KMeta || {},
    alias: indexgtBcgoR29KMeta?.alias || [],
    redirect: indexgtBcgoR29KMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordRZiNMTS75SMeta?.name ?? "settings-password",
    path: passwordRZiNMTS75SMeta?.path ?? "password",
    meta: passwordRZiNMTS75SMeta || {},
    alias: passwordRZiNMTS75SMeta?.alias || [],
    redirect: passwordRZiNMTS75SMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profilesgQnJU6ygZMeta?.name ?? "settings-profile",
    path: profilesgQnJU6ygZMeta?.path ?? "profile",
    meta: profilesgQnJU6ygZMeta || {},
    alias: profilesgQnJU6ygZMeta?.alias || [],
    redirect: profilesgQnJU6ygZMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacesh1WWI8fNhFMeta?.name ?? "settings-workspaces",
    path: workspacesh1WWI8fNhFMeta?.path ?? "workspaces",
    meta: workspacesh1WWI8fNhFMeta || {},
    alias: workspacesh1WWI8fNhFMeta?.alias || [],
    redirect: workspacesh1WWI8fNhFMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorvXogfDNGlhMeta?.name ?? "subscriptions-error",
    path: errorvXogfDNGlhMeta?.path ?? "/subscriptions/error",
    meta: errorvXogfDNGlhMeta || {},
    alias: errorvXogfDNGlhMeta?.alias || [],
    redirect: errorvXogfDNGlhMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successrSnbwewiFqMeta?.name ?? "subscriptions-success",
    path: successrSnbwewiFqMeta?.path ?? "/subscriptions/success",
    meta: successrSnbwewiFqMeta || {},
    alias: successrSnbwewiFqMeta?.alias || [],
    redirect: successrSnbwewiFqMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsvXrgaxNFDNMeta?.name ?? "terms-conditions",
    path: terms_45conditionsvXrgaxNFDNMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsvXrgaxNFDNMeta || {},
    alias: terms_45conditionsvXrgaxNFDNMeta?.alias || [],
    redirect: terms_45conditionsvXrgaxNFDNMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: editw48xO2GSCpMeta?.name ?? "views-slug-edit",
    path: editw48xO2GSCpMeta?.path ?? "/views/:slug()/edit",
    meta: editw48xO2GSCpMeta || {},
    alias: editw48xO2GSCpMeta?.alias || [],
    redirect: editw48xO2GSCpMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexV99RVN8XBfMeta?.name ?? "views-slug",
    path: indexV99RVN8XBfMeta?.path ?? "/views/:slug()",
    meta: indexV99RVN8XBfMeta || {},
    alias: indexV99RVN8XBfMeta?.alias || [],
    redirect: indexV99RVN8XBfMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showkSjucLiS06Meta?.name ?? undefined,
    path: showkSjucLiS06Meta?.path ?? "/views/:slug()/show",
    meta: showkSjucLiS06Meta || {},
    alias: showkSjucLiS06Meta?.alias || [],
    redirect: showkSjucLiS06Meta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexLyx89vlrx8Meta?.name ?? "views-slug-show",
    path: indexLyx89vlrx8Meta?.path ?? "",
    meta: indexLyx89vlrx8Meta || {},
    alias: indexLyx89vlrx8Meta?.alias || [],
    redirect: indexLyx89vlrx8Meta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: shareCrghxHouHLMeta?.name ?? "views-slug-show-share",
    path: shareCrghxHouHLMeta?.path ?? "share",
    meta: shareCrghxHouHLMeta || {},
    alias: shareCrghxHouHLMeta?.alias || [],
    redirect: shareCrghxHouHLMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexREXdbw6jOGMeta?.name ?? "views-create",
    path: indexREXdbw6jOGMeta?.path ?? "/views/create",
    meta: indexREXdbw6jOGMeta || {},
    alias: indexREXdbw6jOGMeta?.alias || [],
    redirect: indexREXdbw6jOGMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indextlKM6mGQUGMeta?.name ?? "views",
    path: indextlKM6mGQUGMeta?.path ?? "/views",
    meta: indextlKM6mGQUGMeta || {},
    alias: indextlKM6mGQUGMeta?.alias || [],
    redirect: indextlKM6mGQUGMeta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsXZ3zNJtEn6Meta?.name ?? "views-uploads",
    path: uploadsXZ3zNJtEn6Meta?.path ?? "/views/uploads",
    meta: uploadsXZ3zNJtEn6Meta || {},
    alias: uploadsXZ3zNJtEn6Meta?.alias || [],
    redirect: uploadsXZ3zNJtEn6Meta?.redirect,
    component: () => import("/codebuild/output/src2404366596/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]